const contact = {
    title: '取得協助',
    description: '若需協助申請或產品反饋，請透過下列方法與我們聯繫。',
    discord: {
        title: '加入 Discord 社群',
        description: '與其他開發人員一起尋找解決方案',
        button: '加入',
    },
    github: {
        title: '在 GitHub 上溝通',
        description: '在 GitHub 建立一個問題並提交',
        button: '開啟',
    },
    email: {
        title: '透過電子郵件聯繫支援',
        description: '發送電子郵件以獲得更多資訊和協助',
        button: '發送',
    },
    reserve: {
        title: '與 Logto 團隊預定時間',
        description: '快速預定與我們團隊的一對一會話',
        button: '預定',
    },
};
export default Object.freeze(contact);

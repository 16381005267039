const menu = {
    profile: '帳戶管理',
    language: '語言',
    appearance: {
        label: '主題',
        light: '淺色模式',
        dark: '深色模式',
        system: '跟隨系統',
    },
    sign_out: '退出登錄',
};
export default Object.freeze(menu);

const log_details = {
    page_title: '審計日誌詳情',
    back_to_logs: '返回審計日誌',
    back_to: '返回 {{name}}',
    success: '成功',
    failed: '失敗',
    event_key: '事件 Key',
    application: '應用',
    ip_address: 'IP 地址',
    user: '使用者',
    log_id: '日誌 ID',
    time: '時間',
    user_agent: '使用者代理',
    tab_details: '詳情',
    raw_data: '原始資料',
};
export default Object.freeze(log_details);

const invitation = {
    find_your_tenants: '尋找你的租戶',
    find_tenants_description: '你的電子郵件地址可能已被多個租戶註冊。您可以選擇加入現有的租戶或繼續建立一個新的。',
    create_new_tenant: '建立新租戶',
    email_not_match_title: '您目前以\n{{email}}\n登錄',
    email_not_match_description: '請使用正確的帳戶登入以接受邀請並成為組織成員。',
    switch_account: '登入到另一個帳戶',
    invalid_invitation_status: '無效的邀請。請聯繫管理員並重試。',
    invitation_not_found: '找不到邀請。請聯繫管理員。',
};
export default Object.freeze(invitation);
